<template>
  <div>
    <el-dialog
      title="选择明细"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="60%"
      :close-on-click-modal="false"
    >
      <el-table :data="data" ref="table" v-loading="loading" border stripe max-height="345">
        <el-table-column type="selection"></el-table-column>
        <el-table-column prop="billCode" label="合同编号" show-overflow-tooltip></el-table-column>
        <el-table-column label="合同日期" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.billDate|datetime}}</template>
        </el-table-column>
        <el-table-column prop="contractName" label="合同名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="projectName" label="所属项目" show-overflow-tooltip></el-table-column>
        <el-table-column label="合同金额" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.contractAmount|thousands}}</template>
        </el-table-column>
        <el-table-column label="结算金额" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.balanceAmount|thousands}}</template>
        </el-table-column>
        <el-table-column label="应收金额" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.remainAmount|thousands}}</template>
        </el-table-column>
      </el-table>
      <el-button type="info" @click="save" style="margin:10px 0 0 0;">选定</el-button>
    </el-dialog>
  </div>
</template>

<script>
import setName from "@/common/setName";
export default {
  props: ["item", "clientId"],
  data() {
    return {
      visible: false,
      loading: false,
      data: []
    };
  },
  watch: {
    item: {
      handler: function() {
        this.getData();
        this.visible = true;
      },
      deep: true
    }
  },
  methods: {
    getData() {
      this.data = [];
      this.loading = true;
      this.$get("Receipt/GetContractItem", {
        clientId: this.clientId
      })
        .then(r => {
          r.forEach(item => {
            if (item.isInit) {
              item.projectName = "-";
            } else {
              setName("Project", item, "projectId", "projectName");
            }

            item.remainAmount = this.amountFormat(item.contractAmount - item.balanceAmount);
          });
          this.data = r;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      let selection = this.$refs.table.selection;
      if (selection.length == 0) {
        this.$message.error("请选择合同明细");
        return;
      }
      let res = [];
      selection.forEach(item => {
        res.push({
          isInit: item.isInit,
          contractId: item.id,
          billCode: item.billCode,
          billDate: item.billDate,
          contractName: item.contractName,
          projectName: item.projectName,
          billAmount: item.contractAmount,
          remainAmount: item.remainAmount,
          amount: 0,
          balanceAmount: 0,
          remark: ""
        });
      });
      this.$emit("success", res);
      this.visible = false;
    }
  }
};
</script>

<style>
</style>