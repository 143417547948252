<template>
  <div>
    <div class="bill-title">合同收款</div>
    <el-form
      :model="form"
      status-icon
      :rules="rules"
      ref="form"
      label-width="80px"
    >
      <el-row>
        <el-col :span="6">
          <el-form-item label="单据编号" prop="billCode">
            <el-input v-model="form.billCode" placeholder="收款编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单据日期" prop="billDate">
            <el-date-picker
              style="width: 150px"
              @change="getBillCode(7)"
              v-model="form.billDate"
              placeholder="单据日期"
              :clearable="false"
              :editable="false"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="客户" prop="clientId">
            <base-select
              @input="clientChange"
              info="Client"
              v-model="form.clientId"
            ></base-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="往来余额">
            <el-tag>{{ clientBalance | thousands }}</el-tag>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="摘要">
        <el-input v-model="form.remark" placeholder="摘要" clearable></el-input>
      </el-form-item>
      <el-form-item label="单据附件">
        <BillAttach :attachId="form.attachId"></BillAttach>
      </el-form-item>
      <el-form-item>
        <template slot="label">
          <strong>收款明细</strong>
        </template>
        <el-button-group>
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-plus"
            @click="selectDetail"
          ></el-button>
          <el-button size="mini" type="success" @click="balanceAll"
            >自动结算</el-button
          >
        </el-button-group>
      </el-form-item>
      <div style="margin: -8px 0px 8px 80px">
        <el-table :data="detailList" stripe border>
          <el-table-column
            type="index"
            width="50"
            label="序号"
          ></el-table-column>
          <el-table-column
            label="合同编号"
            prop="billCode"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="合同日期" show-overflow-tooltip>
            <template slot-scope="scope">{{
              scope.row.billDate | datetime
            }}</template>
          </el-table-column>
          <el-table-column
            label="合同名称"
            prop="contractName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="所属项目"
            prop="projectName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="合同金额" show-overflow-tooltip>
            <template slot-scope="scope">{{
              scope.row.billAmount | thousands
            }}</template>
          </el-table-column>
          <el-table-column label="应收金额" show-overflow-tooltip>
            <template slot-scope="scope">{{
              scope.row.remainAmount | thousands
            }}</template>
          </el-table-column>
          <el-table-column label="本次收款">
            <template slot-scope="scope">
              <el-popover placement="top-start" trigger="focus">
                <span style="color: teal">{{ scope.row.amount | money }}</span>
                <el-input
                  @input="setAmount(scope.row)"
                  slot="reference"
                  type="number"
                  v-model="scope.row.amount"
                  @mousewheel.native.prevent
                ></el-input>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="本次结算">
            <template slot-scope="scope">
              <el-popover placement="top-start" trigger="focus">
                <span style="color: teal">{{
                  scope.row.balanceAmount | money
                }}</span>
                <el-input
                  slot="reference"
                  type="number"
                  v-model="scope.row.balanceAmount"
                  @mousewheel.native.prevent
                ></el-input>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="备注">
            <template slot-scope="scope">
              <el-input v-model="scope.row.remark" clearable></el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-form-item>
        <span v-if="canntEditMsg" style="color: red"
          >{{ canntEditMsg }} 单据不可编辑</span
        >
        <el-button-group v-else>
          <el-button type="info" @click="save(false)">保存关闭</el-button>
          <el-button type="success" @click="save(true)">保存新增</el-button>
          <el-button type="warning" @click="cancel">取消关闭</el-button>
          <el-button type="danger" @click="deleteBill" v-if="form.id&&hasPermission('DeleteBill')"
            >删除单据</el-button
          >
        </el-button-group>

        <span style="margin-left: 10px">
          收款合计
          <el-tag type="warning">{{ totalAmount | thousands }}</el-tag
          >元
        </span>
        <span style="margin-left: 10px">
          结算合计
          <el-tag type="warning">{{ totalBalanceAmount | thousands }}</el-tag
          >元
        </span>
      </el-form-item>
      <el-form-item>
        <operator-box :item="form"></operator-box>
      </el-form-item>
    </el-form>
    <div>
      <receipt-detail
        :item="detail"
        :clientId="form.clientId"
        @success="addDetail"
      ></receipt-detail>
    </div>
  </div>
</template>

<script>
import billcode from "@/common/billcodeMixins.js";
import BaseSelect from "@/components/BaseSelect.vue";
import ReceiptDetail from "@/views/receipt/Detail.vue";
import OperatorBox from "@/components/OperatorBox";
import guid from "@/common/guid.js";
import BillAttach from "@/components/BillAttach";
export default {
  mixins: [billcode],
  components: {
    BaseSelect,
    ReceiptDetail,
    OperatorBox,
    BillAttach,
  },
  data() {
    return {
      routeTab: "合同收款",
      form: {},
      rules: {
        billCode: [{ required: true, message: "编号不能为空" }],
        billDate: [{ required: true, message: "日期不能为空" }],
        clientId: [{ required: true, message: "客户不能为空" }],
      },
      detailList: [],
      detail: {},

      stopList: [], //停用基础信息
      canntEditMsg: "", //不可编辑的提示信息

      clientBalance: 0,
    };
  },
  computed: {
    totalAmount() {
      let res = 0;
      this.detailList.forEach((item) => {
        res += Number(item.amount);
      });
      return this.amountFormat(res);
    },
    totalBalanceAmount() {
      let res = 0;
      this.detailList.forEach((item) => {
        res += Number(item.balanceAmount);
      });
      return this.amountFormat(res);
    },
  },
  mounted() {
    this.init(this.$route.query.id);
  },
  beforeDestroy() {
    this.stopList.forEach((item) => {
      this.$store.commit("removeBaseInfo", {
        info: item.info,
        id: item.baseInfo.id,
      });
    });
  },
  methods: {
    init(id) {
      this.stopList = [];
      this.canntEditMsg = "";
      if (id) {
        this.editInit(id);
      } else {
        this.addInit();
      }
    },
    //新增
    addInit() {
      this.clientBalance = 0;

      this.form = {
        billCode: "",
        billDate: new Date(),
        clientId: "",
        remark: "",
        attachId: guid(),
      };
      this.detailList = [];
      this.getBillCode(7);
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    //编辑
    editInit(id) {
      this.$get("Receipt/Detail", {
        id,
      }).then((r) => {
        this.routeTab = r.bill.billCode;
        this.form = { ...r.bill };
        this.detailList = [...r.detail];
        this.clientBalance = r.clientBalance;
        this.stopList = r.stopList;
        this.canntEditMsg = r.canntEditMsg;
        this.stopList.forEach((item) => {
          this.$store.commit("addBaseInfo", {
            info: item.info,
            item: item.baseInfo,
          });
        });
      });
    },
    clientChange(clientId) {
      this.detailList = [];
      if (!clientId) {
        this.clientBalance = 0;
        return;
      }
      this.$get("Receipt/GetClientBalance", {
        clientId,
      }).then((r) => {
        this.clientBalance = r;
      });
    },
    setAmount(row) {
      row.balanceAmount = row.amount;
    },
    selectDetail() {
      if (!this.form.clientId) {
        this.$message.error("请选客户");
        return;
      }
      this.detail = {};
    },
    addDetail(array) {
      this.detailList = array;
    },
    balanceAll() {
      this.detailList.forEach((item) => {
        item.amount = item.remainAmount;
        item.balanceAmount = item.remainAmount;
      });
    },
    //保存
    save(isContinue) {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.detailList.length == 0) {
          this.$message.error("请添加收款明细");
          return;
        }
        let bill = { ...this.form };
        let detail = [];
        let error = "";
        this.detailList.forEach((item) => {
          if (item.amount < 0 || item.balanceAmount <= 0) {
            error = "收款和结算金额需要大于0";
          }
          detail.push({
            isInit: item.isInit,
            contractId: item.contractId,
            remainAmount: item.remainAmount,
            amount: item.amount,
            balanceAmount: item.balanceAmount,
            remark: item.remark,
          });
        });
        if (error) {
          this.$message.error(error);
          return;
        }
        let loading = this.$loading({
          lock: true,
          text: "保存中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let url = "";
        if (this.form.id) {
          url = "Receipt/Edit";
        } else {
          url = "Receipt/Add";
        }
        this.$post(url, {
          bill,
          detail,
        })
          .then((r) => {
            this.$message.success("操作成功！");
            if (isContinue) {
              this.$tabs.close({
                to: {
                  name: "ReceiptItem",
                  query: {},
                },
                refresh: true,
              });
            } else {
              this.$tabs.close();
            }
          })
          .finally(() => {
            loading.close();
          });
      });
    },
    //删除单据
    deleteBill() {
      this.$confirm("将删除此收款单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let loading = this.$loading({
            lock: true,
            text: "删除中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.$post("Receipt/Delete?id=" + this.form.id)
            .then((r) => {
              this.$message.success("操作成功！");
              this.$g.deleteAttach(this.form.attachId);
              this.$tabs.close();
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch(() => {});
    },
    cancel() {
      if (!this.form.id) {
        this.$g.deleteAttach(this.form.attachId);
      }
      this.$tabs.close();
    },
  },
};
</script>

<style>
</style>